<template>
  <step-card title="step_3_title" :is-loading="isLoading">
    <template v-slot:body>
      <div class="mb-3">
        <label for="firstUsage" class="form-label">{{$t('step_3_input_label')}}</label>
        <input v-model="firstUsage" type="date" class="form-control" id="firstUsage">
      </div>
    </template>
    <template v-slot:step-info>
      {{ $t('step_3_info') }}
    </template>
    <template v-slot:btn-previous>
      <button @click="$emit('prevStep')" class="btn btn-primary">{{$t('previous')}}</button>
    </template>
    <template v-slot:btn-next>
      <button @click="nextStep" class="btn btn-primary">{{$t('calculate_bpm')}}</button>
    </template>
  </step-card>
</template>

<script>
import StepCard from "@/components/StepCard";
export default {
  components: {StepCard},
  data() {
    return {
      firstUsage: null,
      isLoading: false,
    };
  },
  methods: {
    nextStep() {
      localStorage.setItem("firstUsage", this.firstUsage)
      this.isLoading = true
      this.$emit('purchase')
    }
  }
};
</script>
