<template>
  <div class="review-container">
    <transition name="slide" mode="out-in">
      <review-card :key="currentReviewIndex" :review="currentReview"/>
    </transition>
  </div>
</template>

<script>
import ReviewCard from "@/components/ReviewCard";

export default {
  components: {
    ReviewCard,
  },
  data() {
    return {
      reviews: [
          {
              "name": "Lisa",
              "date": "10-08-2023",
              "text": "Geweldige website! Ik kon mijn BPM in een handomdraai berekenen. Echt super makkelijk en de laagste berekening!",
              "score": "★★★★★"
          },
          {
              "name": "Jan",
              "date": "11-08-2023",
              "text": "Wat een simpel en handig platform. Binnen een minuut had ik mijn BPM-berekening klaar!",
              "score": "★★★★★"
          },
          {
              "name": "Emma",
              "date": "12-08-2023",
              "text": "Deze site maakte het BPM-berekeningsproces zo eenvoudig. Ik hoefde alleen maar wat gegevens in te voeren en kreeg direct mijn resultaat.",
              "score": "★★★★★"
          },
          {
              "name": "Daan",
              "date": "13-08-2023",
              "text": "Ik ben onder de indruk van de eenvoud van deze website!",
              "score": "★★★★★"
          },
          {
              "name": "Sofia",
              "date": "14-08-2023",
              "text": "Deze website is briljant! Zo gebruiksvriendelijk en ik kreeg de laagste BPM-berekeningen die ik kon vinden. Een absolute aanrader.",
              "score": "★★★★★"
          },
          {
              "name": "Hugo",
              "date": "15-08-2023",
              "text": "Eenvoudig, snel en accuraat. Ik had mijn BPM-berekening binnen enkele ogenblikken!",
              "score": "★★★★★"
          },
          {
              "name": "Olivia",
              "date": "16-08-2023",
              "text": "Deze website is een parel. Zelfs als je geen ervaring hebt, kun je moeiteloos je BPM berekenen.",
              "score": "★★★★★"
          },
          {
              "name": "Noah",
              "date": "17-08-2023",
              "text": "Ik ben echt onder de indruk van deze site. Zo gebruiksvriendelijk en de BPM-berekeningen waren verrassend laag. Ik raad het iedereen aan!",
              "score": "★★★★★"
          },
          {
              "name": "Lotte",
              "date": "18-08-2023",
              "text": "Zelfs zonder technische kennis kon ik mijn BPM moeiteloos berekenen. Als ik het vergelijk met andere sites is de berekening ook nog het laagst!",
              "score": "★★★★★"
          },
          {
              "name": "Bram",
              "date": "18-08-2023",
              "text": "Deze site heeft me tijd en geld bespaard. De BPM-berekening was zo simpel en ik kreeg de laagste berekeningen die ik kon vinden. Geweldig!",
              "score": "★★★★★"
          }
      ],
      currentReviewIndex: 0,
    };
  },
  computed: {
    currentReview() {
      return this.reviews[this.currentReviewIndex];
    },
  },
  created() {
    this.randomizeReview();
    setInterval(this.randomizeReview, 10000);
  },
  methods: {
    randomizeReview() {
      this.currentReviewIndex = Math.floor(Math.random() * this.reviews.length);
    },
  },
};
</script>

<style scoped>
.review-container {
  position: relative;
  height: 180px; /* Adjust the height as needed */
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}

.slide-enter, .slide-leave-to {
  transform: translateX(100%);
}
</style>
