<template>
  <step-card title="step_2_title" :is-loading="isLoading">
    <template v-slot:body>
        <div class="mb-3">
          <label for="co2-uitstoot" class="form-label">{{$t('step_2_input_label')}}</label>
          <input v-model="co2Emission" type="number" class="form-control" id="co2-uitstoot">
        </div>
    </template>
    <template v-slot:step-info>
      {{ $t('step_2_info') }}
    </template>
    <template v-slot:btn-previous>
      <button @click="$emit('prevStep')" class="btn btn-primary">{{$t('previous')}}</button>
    </template>
    <template v-slot:btn-next>
        <button @click="nextStep" class="btn btn-primary">{{$t('next')}}</button>
    </template>
  </step-card>
</template>

<script>
import StepCard from "@/components/StepCard";
export default {
  components: {StepCard},
  data() {
    return {
      co2Emission: null,
      isLoading: false,
    };
  },
  methods: {
    nextStep() {
      localStorage.setItem("co2Emission", this.co2Emission)
      this.isLoading = true
      this.$emit('nextStep')
    }
  }
};
</script>
