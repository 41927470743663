<template>
  <div class="review-box">
    <div class="review-top"><span class="review-title">{{ review.name }}</span> <span class="review-date">{{review.date}}</span></div>
    <div class="review-content">{{ review.text }}</div>
    <div class="review-rating">
      <div class="rating-stars">{{ review.score }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    review: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.review-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.review-top {
  display: flex;
  justify-content: space-between;
}

.review-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.review-date {
  font-size: 10pt;
  line-height: 35px;
}

.review-content {
  font-size: 16px;
  margin-bottom: 10px;
}

.review-rating {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.rating-stars {
  color: gold;
  margin-right: 5px;
}
</style>
