<template>
  <div class="alert alert-danger d-flex align-items-center" role="alert" v-if="hasError">
      {{$t('something_went_wrong')}}
  </div>
  <div class="calculator-frame" >
    <transition name="slide-fade" mode="out-in">
      <component :is="currentStepComponent" :key="currentStepComponentKey" @nextStep="nextStep" @prevStep="prevStep" @purchase="purchase"/>
    </transition>
  </div>
</template>

<script>
import StepOne from '../components/StepOne.vue';
import StepTwo from '../components/StepTwo.vue';
import StepThree from '../components/StepThree.vue';
import axios from "axios";

export default {
  components: {
    StepOne,
    StepTwo,
    StepThree,
  },
  data() {
    return {
      bpmAmount: 100,
      currentStepComponent: 'StepOne', // Start with StepOne
      currentStepComponentKey: 1, // Key for dynamic component
      hasError: false
    };
  },
  methods: {
    nextStep() {
      if (this.currentStepComponent === 'StepOne') {
        this.currentStepComponent = 'StepTwo';
      } else if (this.currentStepComponent === 'StepTwo') {
        this.currentStepComponent = 'StepThree';
      }
      this.currentStepComponentKey++; // Change the key for dynamic component transition
    },
    prevStep() {
      if (this.currentStepComponent === 'StepTwo') {
        this.currentStepComponent = 'StepOne';
      } else if (this.currentStepComponent === 'StepThree') {
        this.currentStepComponent = 'StepTwo';
      }
      this.currentStepComponentKey++; // Change the key for dynamic component transition
    },
    purchase() {
      axios.post("https://payment.verifier.arr-services.nl/api/v1/mollie/_purchase", {
        app: "bpm_calculator",
        product_id: "bpm_calculator.calculation"
      }).then(res => {
        if (res.status === 200 && res.data.success) {
          window.location = res.data.data.payment_url
        } else {
          this.hasError = true
        }
      })
    },
  },
};
</script>

<style scoped>
.calculator-frame {
  width: 100%;
}

.why-alert {
  width: 250px;
}
</style>
