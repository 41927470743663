const translations = {
  texts: {
    nl: {
      "step_2_title": "CO2-Uitstoot",
      "step_2_input_label": "CO2-uitstoot (g/km)",
      "step_2_info": "Voer de co2-uitstoot in om te bepalen hoe hoog de BPM is",
      "step_3_title": "Eerste gebruik van het voertuig",
      "step_3_input_label": "Datum eerste gebruik",
      "step_3_info": "Datum eerste gebruik in het land van herkomst",
      "step_1_title": "Brandstoftype",
      "step_1_input_label": "Kies het brandstoftype",
      "step_1_option_benzine": "Benzine",
      "step_1_option_diesel": "Diesel",
      "step_1_option_hybride/diesel": "Hybride/Diesel",
      "step_1_option_hybride/benzine": "Hybride/Benzine",
      "step_1_option_waterstof": "Waterstof",
      "step_1_option_elektrisch": "Elektrisch",
      "result_title": "BPM Uitslag",
      "calculate_bpm": "Bereken BPM",
      
      "something_went_wrong_help": "Er is iets misgegaan, mocht u betaald hebben stuur dan een mail naar help.arr.services@gmail.com met het volgende referentie code:",
      "something_went_wrong": "Er is iets misgegaan probeer het later opnieuw.",
      "previous": "Vorige",
      "next": "Volgende",
      "again": "Opnieuw"
    }
  },
}


class Translator {
  
  static translate(key) {
    const translation = translations.texts.nl[key]
    return translation ? translation : key
  }
  
}

function $t(key) {
  return Translator.translate(key)
}

export default $t