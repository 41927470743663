<template>
  <bpm-result/>
</template>

<script>
import BpmResult from "@/components/BpmResult";

export default {
  components: {
    BpmResult,
  },
};
</script>

<style scoped>
</style>
