import { createRouter, createWebHistory } from 'vue-router';
import StepsPage from './pages/StepsPage';
import ResultPage from "./pages/ResultPage";

const routes = [
  {
    path: '/',
    name: 'steps',
    component: StepsPage,
  },
  {
    path: '/result',
    name: 'result',
    component: ResultPage,
  },
];

const router = createRouter({
  history: createWebHistory(), // Use history mode for clean URLs (requires server configuration)
  routes,
});

export default router;
