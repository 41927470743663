import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/global.css';
import $t from "./translations"
import router from './router';

const appInstance = createApp(App)
appInstance.use(router);
appInstance.provide("$t", $t);
appInstance.config.globalProperties.$t = $t

appInstance.mount('#app')