<template>
  <div class="row">
    <div class="col-md-8 col-lg-4">
      <div class="step-card card">
        <div class="card-header bg-primary text-white">
          <div class="title-container">
            <h3 class="mb-0">{{$t(title)}}</h3>
            <div class="spinner-border" role="status" v-if="isLoading">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <slot name="body"/>
          <span class="step-info">
            <slot name="step-info"/>
          </span>
        </div>
        <div class="card-footer container">
          <div class="previous">
            <slot name="btn-previous"/>
          </div>
          <div class="next">
            <slot name="btn-next"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepCard",
  props: {
    title: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true,
    }
  }
}
</script>

<style scoped>
.previous {
  float: left;
}
.next {
  float: right;
}
/* Custom styles for the component */
.card {
  height: 320px;
}
.card-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.row {
  justify-content: center;
  --bs-gutter-x: 0;
}

.title-container {
  display: flex;
  justify-content: space-between;
}

</style>
<style>
.step-info {
  font-size: 8pt;
}
</style>