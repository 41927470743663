<template>
  <div class="all-container">
    <div class="image-container">
      <img src="./assets/logo.png" alt="image" class="main-image"/>
    </div>
    <review-cards/>
    <div class="calculator-frame">
      <main>
        <router-view></router-view>
      </main>
    </div>
  </div>
  <div class="copy-right">
    <p>&copy; 2023 AR Rahimi. All rights reserved.</p>
  </div>
</template>

<script>

import ReviewCards from "@/components/ReviewCards";
export default {
  components: {ReviewCards}
};
</script>

<style scoped>

.all-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 35px;
  align-items: center;
  height: 100vh
}

.calculator-frame {
  display: flex;
  align-items: center;;
  width: 100%;
  justify-content: center;
  /*d-flex align-items-center justify-content-center vh-100*/
}

.image-container {
  max-width: min(90%, 480px);
}

.main-image {
  max-width: 100%;
}

/* For screens with a width less than or equal to 768px (typical mobile devices) */
@media (max-width: 768px) {
  .main-image {
    max-height: 160px;
  }
}

/* For screens with a width greater than 768px (typical desktop screens) */
@media (min-width: 769px) {
  .main-image {
    max-height: 320px;
  }
}


/* Slide-in and slide-out animation */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */
{
  opacity: 0;
  transform: translateX(10px);
}

.copy-right {
  display: flex;
  justify-content: center;
}

main {
  width: 100%;
}
</style>
