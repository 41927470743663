<template>
  <step-card title="step_3_title" :is-loading="isLoading">
    <template v-slot:body>
      <div class="mb-3">
        <label for="fuelType" class="form-label">{{$t('step_1_input_label')}}</label>
        <select v-model="fuelType" class="form-select" id="fuelType">
          <option value="benzine">{{$t('step_1_option_benzine')}}</option>
          <option value="diesel">{{$t('step_1_option_diesel')}}</option>
          <option value="hybride/diesel">{{$t('step_1_option_hybride/diesel')}}</option>
          <option value="hybride/benzine">{{$t('step_1_option_hybride/benzine')}}</option>
          <option value="waterstof">{{$t('step_1_option_waterstof')}}</option>
          <option value="elektrisch">{{$t('step_1_option_elektrisch')}}</option>
        </select>
      </div>
    </template>
    <template v-slot:btn-next>
      <button v-if="fuelType === 'waterstof' || fuelType === 'elektrisch'" @click="nextStep" class="btn btn-success">{{$t('calculate_bpm')}}</button>
      <button v-else @click="nextStep" class="btn btn-success">{{$t('next')}}</button>
    </template>
  </step-card>
</template>

<script>
import StepCard from "@/components/StepCard";
export default {
  components: {StepCard},
  data() {
    return {
      fuelType: 'benzine',
      isLoading: false,
    };
  },
  methods: {
    nextStep() {
      this.isLoading = true
      localStorage.setItem("fuelType", this.fuelType)
      if (this.fuelType === "waterstof" || this.fuelType === "elektrisch") {
        this.$emit('purchase')
      } else {
        this.$emit('nextStep')
      }
    }
  }
};
</script>
