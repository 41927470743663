<template>
  <div class="alert alert-danger d-flex align-items-center" role="alert" v-if="hasError">
      {{$t('something_went_wrong_help')}}
      {{reference}}
  </div>
  <step-card title="result_title">
    <template v-slot:body>
      <div class="mb-3 result-container">
        <template v-if="bpm !== null">
          <span>BPM voor het importeren van het voertuig is</span>
          <h1>€{{parseInt(bpm)}}</h1>
        </template>
        <template v-else>
          <span>BPM aan het berekenen</span>
        </template>
      </div>
    </template>
    <template v-slot:btn-next>
      <button @click="again" class="btn btn-success">{{$t('again')}}</button>
    </template>
  </step-card>
</template>

<script>
import StepCard from "@/components/StepCard";
import axios from "axios";
export default {
  components: {StepCard},
  data() {
    return {
      bpm: null,
      reference: null,
      hasError: false
    }
  },
  mounted() {
    this.calculateBpm()
  },
  methods: {
    calculateBpm() {
      const fuelType = localStorage.getItem("fuelType")
      if (fuelType === "elektrisch" || fuelType === "waterstof") {
        this.bpm = 0
        return
      }
      const firstUsage = localStorage.getItem("firstUsage")
      const co2Emission = localStorage.getItem("co2Emission")
      this.reference = this.$route.query.purchase_token
      axios.post("https://computingv2.arr-services.nl/api/v1/calculate/_bpm", {
        co2_emission: co2Emission,
        fuel_type: fuelType,
        first_usage: firstUsage,
        app: this.$route.query.app,
        product_id: this.$route.query.product_id,
        token: this.$route.query.purchase_token
      }).then(res => {
        if (res.status === 200) {
          this.bpm = res.data.data.bpm
          this.gtag_conversion_report()
        } else {
          this.hasError = true
        }
      })
    },
    gtag_conversion_report(url) {
      let callback = function () {
        if (typeof(url) != 'undefined') {
          window.location = url;
        }
      };
       /* eslint-disable */
      gtag('event', 'conversion', {
          'send_to': 'AW-852312596/8ZD_CKuKw9IYEJSEtZYD',
          'transaction_id': this.$route.query.purchase_token,
          'event_callback': callback
      });
      return false;
    },
    again() {
      localStorage.removeItem("fuelType")
      localStorage.removeItem("firstUsage")
      localStorage.removeItem("co2Emission")
      this.$router.push({name: "steps"})
    }
  }
};
</script>
<style scoped>
.result-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20vh;
}
</style>
